






































































































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import DistributeBalanceUnallocatedWealthViewModel
  from '@/vue-app/view-models/components/goals-dashboard/unallocated-balance/distritute-unallocated-balance-view-model';

@Component({
  name: 'DistributeBalanceUnallocatedWealthModal',
  components: {
    DistributeUnallocatedBalanceSuccess: () => import('@/vue-app/components/goals-dashboard/unallocated-balance/DistributeUnallocatedBalanceSuccess.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class DistributeBalanceUnallocatedWealthModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('productId', { type: String, required: true })
  synced_product_id!: string;

  @PropSync('productName', { type: String, required: true })
  synced_product_name!: string;

  view_model = Vue.observable(
    new DistributeBalanceUnallocatedWealthViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  created() {
    this.view_model.initialize(this.synced_product_id);
  }
}
